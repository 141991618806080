import type { LexicalEditor, TextNode, ElementNode, ParagraphNode } from 'lexical'
import type { LinkNode, LinkAttributes } from '@lexical/link'
import { $getRoot, $createTextNode, $createParagraphNode, $isTextNode, $isParagraphNode } from 'lexical'
import { $createLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import React, { useCallback, useState } from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import FormatHyperLinkIcon from '@mui/icons-material/Link'
import Button from '@mui/material/Button'
import { isValidUrl } from './utils/url'

type LinkEditorDialogFormPropsInitialValues =
    | { text: string; url: string; node: LinkNode | TextNode | ElementNode }
    | undefined
type LinkEditorDialogFormProps = {
    onClose: () => void
    onSubmit: (text: string, url: string, initialValues: LinkEditorDialogFormPropsInitialValues) => void
    initialValues: LinkEditorDialogFormPropsInitialValues
}
const LinkEditorDialogForm = ({ initialValues, onClose, onSubmit }: LinkEditorDialogFormProps): JSX.Element => {
    const [url, setUrl] = useState(initialValues?.url || '')
    const [text, setText] = useState(initialValues?.text || '')

    const trimmedUrl = url.trim()
    const isValid = !!(trimmedUrl && isValidUrl(trimmedUrl))
    const onFormSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            e.stopPropagation()
            if (isValid) {
                onSubmit(text || trimmedUrl, trimmedUrl, initialValues)
            }
        },
        [isValid, onSubmit, text, trimmedUrl, initialValues]
    )

    const isNewLink = !initialValues?.url
    return (
        <>
            <DialogTitle>{isNewLink ? 'Insert' : 'Edit'} Link</DialogTitle>
            <form noValidate onSubmit={onFormSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Text"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                fullWidth
                                // TODO: be able to modify text for new links in paragraphs
                                disabled={isNewLink && !!initialValues?.text}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" type="submit" disabled={!isValid}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const defaultOpts: Readonly<LinkAttributes> = { rel: 'noopener', target: '_blank' }

export type LinkEditorProps = {
    lexicalEditor: LexicalEditor
    link: LinkEditorDialogFormPropsInitialValues
}
const LinkEditor = ({ lexicalEditor: editor, link }: LinkEditorProps): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onClose = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

    const node = link?.node
    const isLink = !!link?.url
    const onSubmit = useCallback(
        (text: string, url: string, initialValues: LinkEditorDialogFormPropsInitialValues) => {
            // Replace the text content of the LinkNode
            editor.update(() => {
                const updatedText = text || url
                const updatedLink = $createLinkNode(url, defaultOpts)
                updatedLink.append($createTextNode(updatedText))
                if (node) {
                    // update the link if it already exists
                    if ($isLinkNode(node)) {
                        node.setURL(url)
                        node.getChildren().forEach((child) => {
                            if ($isTextNode(child)) {
                                child.setTextContent(updatedText)
                            }
                        })
                    } else if ($isTextNode(node)) {
                        // add a link to the existing text or make a new node
                        if (initialValues?.text) {
                            editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url, ...defaultOpts })
                        } else {
                            node.insertAfter(updatedLink)
                        }
                    } else {
                        // replace the selected node with a new link
                        node.clear()
                        node.append(updatedLink)
                    }
                } else {
                    const root = $getRoot()

                    let rootParagraphNode = root.getLastChild()
                    if (!$isParagraphNode(rootParagraphNode)) {
                        rootParagraphNode = $createParagraphNode()
                        root.append(rootParagraphNode)
                    }

                    ;(rootParagraphNode as ParagraphNode).append(updatedLink)
                }
            })
            onClose()
        },
        [editor, onClose, node]
    )

    return (
        <>
            <IconButton
                size="small"
                onClick={() => setIsModalOpen(true)}
                className={`toolbar-item spaced ${isLink ? 'active' : ''}`}
                aria-label="Insert Link"
                sx={{
                    color: 'grey.600',
                    '&:hover': {
                        backgroundColor: isLink ? 'action.focus' : 'action.hover',
                    },
                }}
            >
                <FormatHyperLinkIcon />
            </IconButton>
            <Dialog open={isModalOpen} onClose={onClose}>
                {isModalOpen && <LinkEditorDialogForm initialValues={link} onClose={onClose} onSubmit={onSubmit} />}
            </Dialog>
        </>
    )
}

export default LinkEditor
