export const isValidUrl = (html: unknown): boolean => {
    if (typeof html !== 'string') return false

    // https://developer.mozilla.org/en-US/docs/Web/API/URL/canParse_static
    // TODO: figure out why TS doesn't recognize this function existing (upgrade?)
    // if ('canParse' in window.URL) return URL.canParse(html)

    try {
        new URL(html) // eslint-disable-line no-new
        return true
    } catch {
        return false
    }
}
