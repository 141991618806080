import type { LinkEditorProps } from '../LinkEditor'
import {
    SELECTION_CHANGE_COMMAND,
    FORMAT_TEXT_COMMAND,
    $getSelection,
    $isRangeSelection,
    $createParagraphNode,
} from 'lexical'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isLinkNode } from '@lexical/link'
import { $setBlocksType } from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
    ListNode,
} from '@lexical/list'
import { $createQuoteNode, $isHeadingNode } from '@lexical/rich-text'
import Toolbar from '@mui/material/Toolbar'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import IconButton from '@mui/material/IconButton'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlined from '@mui/icons-material/FormatUnderlined'
import Divider from '@mui/material/Divider'
import LinkEditor from '../LinkEditor'
import { getSelectedNode } from '../utils/getSelectedNode'

const LowPriority = 1

const ToolbarPlugin = (): JSX.Element => {
    const [editor] = useLexicalComposerContext()
    const toolbarRef = useRef(null)
    const [blockType, setBlockType] = useState('paragraph')
    const [isBold, setIsBold] = useState(false)
    const [isItalic, setIsItalic] = useState(false)
    const [isUnderline, setIsUnderline] = useState(false)
    const [link, setLink] = useState<LinkEditorProps['link']>()

    const updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
            const elementKey = element.getKey()
            const elementDOM = editor.getElementByKey(elementKey)
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode)
                    const type = parentList ? parentList.getTag() : element.getTag()
                    setBlockType(type)
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                    setBlockType(type)
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'))
            setIsItalic(selection.hasFormat('italic'))
            setIsUnderline(selection.hasFormat('underline'))

            // Update links
            const node = getSelectedNode(selection)
            const parent = node.getParent()
            if ($isLinkNode(parent)) {
                setLink({ text: parent.getTextContent(), url: parent.getURL(), node: parent })
            } else if ($isLinkNode(node)) {
                setLink({ text: node.getTextContent(), url: node.getURL(), node })
            } else {
                setLink({ text: selection.getTextContent(), url: '', node })
            }
        } else {
            setLink(undefined)
        }
    }, [editor])

    useEffect(
        () =>
            mergeRegister(
                editor.registerUpdateListener(({ editorState }) => {
                    editorState.read(() => {
                        updateToolbar()
                    })
                }),
                editor.registerCommand(
                    SELECTION_CHANGE_COMMAND,
                    () => {
                        updateToolbar()
                        return false
                    },
                    LowPriority
                )
            ),
        [editor, updateToolbar]
    )

    const formatItalic = useCallback(() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic'), [editor])
    const formatBold = useCallback(() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold'), [editor])
    const formatUnderline = useCallback(() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline'), [editor])

    const isUL = blockType === 'ul'
    const formatUnorderedList = useCallback(() => {
        if (!isUL) {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0)
        }
    }, [editor, isUL])

    const isOL = blockType === 'ol'
    const formatOrderedList = useCallback(() => {
        if (!isOL) {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0)
        }
    }, [editor, isOL])

    const isQuote = blockType === 'quote'
    const formatQuote = useCallback(() => {
        if (!isQuote) {
            editor.update(() => {
                const selection = $getSelection()
                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, $createQuoteNode)
                }
            })
        } else {
            editor.update(() => {
                const selection = $getSelection()
                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, $createParagraphNode)
                }
            })
        }
    }, [editor, isQuote])

    return (
        <Toolbar
            ref={toolbarRef}
            sx={{
                backgroundColor: 'common.white',
                borderTop: 1,
                borderColor: 'divider',
                minHeight: '36px',
                p: 0.25,
                gap: 0.5,
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
            }}
            disableGutters
            variant="dense"
        >
            <IconButton
                size="small"
                onClick={formatBold}
                sx={{
                    backgroundColor: isBold ? 'action.selected' : undefined,
                    color: isBold ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isBold ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Bold"
            >
                <FormatBoldIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={formatItalic}
                sx={{
                    backgroundColor: isItalic ? 'action.selected' : undefined,
                    color: isItalic ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isItalic ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Italics"
            >
                <FormatItalicIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={formatUnderline}
                sx={{
                    backgroundColor: isUnderline ? 'action.selected' : undefined,
                    color: isUnderline ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isUnderline ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Underline"
            >
                <FormatUnderlined />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ mx: 0.25 }} />
            <IconButton
                size="small"
                onClick={formatUnorderedList}
                sx={{
                    backgroundColor: isUL ? 'action.selected' : undefined,
                    color: isUL ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isUL ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Bulleted List"
            >
                <FormatListBulletedIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={formatOrderedList}
                sx={{
                    backgroundColor: isOL ? 'action.selected' : undefined,
                    color: isOL ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isOL ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Numbered List"
            >
                <FormatListNumberedIcon />
            </IconButton>
            <IconButton
                size="small"
                onClick={formatQuote}
                sx={{
                    backgroundColor: isQuote ? 'action.selected' : undefined,
                    color: isQuote ? 'common.black' : 'grey.600',
                    '&:hover': {
                        backgroundColor: isQuote ? 'action.focus' : 'action.hover',
                    },
                }}
                aria-label="Format Quote"
            >
                <FormatQuoteIcon />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ mx: 0.25 }} />
            <LinkEditor link={link} lexicalEditor={editor} />
        </Toolbar>
    )
}

export default ToolbarPlugin
